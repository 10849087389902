<template>
    <div class="promtePage">
        <div class="navbar">
            <div class="headBox">
                <div @click="$router.go(-1)">
                    <svg-icon iconClass="leftArrow" class="leftArrow"></svg-icon>
                </div>
                <div class="title" v-for="item,index in tabs" :key="item.key" :class="{'isActive': activeTab === index}"
                    @click="tabClick(index)">{{item.title}}</div>
                <div style="width: 25px"></div>
            </div>
        </div>
        <swiper ref="mySwiper" :options="swiperOptions" class="mySwiper">
            <swiper-slide>
                <AppPromote></AppPromote>
            </swiper-slide>
            <!-- <swiper-slide>
                <GamePromote></GamePromote>
            </swiper-slide> -->
        </swiper>
    </div>
</template>

<script>
    import 'swiper/dist/css/swiper.css';
    import AppPromote from './component/AppPromote.vue'
    // import GamePromote from './component/GamePromote.vue'
     import { swiper, swiperSlide } from 'vue-awesome-swiper';
    export default {
        components: {
            swiper,
            swiperSlide,
            AppPromote,
            // GamePromote,
        },
        data() {
            return {
                tabs: [{
                        title: 'APP推广',
                        key: 'app'
                    },
                    // {
                    //     title: '游戏推广',
                    //     key: 'game'
                    // },
                ],
                activeTab: 0,
                swiperOptions: {
                    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
                    observeParents: true, // 修改swiper的父元素时，自动初始化swiper
                    // aotoHeight:true,
                    on: {
                        slideChangeTransitionEnd: () => {
                            this.activeTab = this.$refs.mySwiper.swiper.activeIndex;
                        }
                    }
                },
            }
        },
        methods: {
            tabClick(index) {
                this.activeTab = index;
                this.$refs.mySwiper.swiper.slideTo(index);
            },
        }
    }
</script>

<style lang='scss' scoped>
    .promtePage {
        // background: $whiteThree;
        height: 100%;
        color: $white1;
        // padding: 44px 0 0;
        // overflow: auto;
        // -ms-overflow-style: none;
        // .navbar {
        //     position: fixed;
        //     top: 0;
        //     width: 100%;
        //     z-index: 2;
        // }

        // .mySwiper {
        //     padding-top: 44px;
        // }

        .headBox {
            width: 100%;
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: $vermillion;
            padding: 0 16px;
            box-sizing: border-box;
            font-size: 16px;
            color: rgba($color: $white1, $alpha: 0.8);

            .title {
                position: relative;
            }

            .leftArrow {
                color: $white1;
            }

            .isActive {
                // font-size: 18px;
                // font-weight: 600;
                color: $white1;
            }

            // .isActive::after {
            //     content: "";
            //     width: 100%;
            //     height: 3px;
            //     background: #fff;
            //     display: block;
            //     // margin: 2px auto 0;
            //     border-radius: 3px;
            //     position: absolute;
            //     bottom: -7px;
            //     left: 50%;
            //     transform: translate(-50%, 0);
            //     // border-bottom: 5px solid #fff;
            // }
        }
    }
    // .promtePage::-webkit-scrollbar {
    //   display: none;
    // }
</style>