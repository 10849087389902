<template>
  <div class="appPromotePage">
    <!-- 收益总额 -->
    <div class="app_title">收益总额</div>
    <div class="app_amout_box">
      <div class="app_amount">
        <svg-icon iconClass="gold"></svg-icon>
        <span>{{incomeAmount || '0.00'}}</span>
      </div>
      <div class="app_withdrawBtn" @click="toWithdraw">提现</div>
    </div>
    <div class="app_promote_line"></div>
    <!-- 推广信息 -->
    <van-grid class="promoteInfo" column-num="3" :border="false">
      <van-grid-item use-slot>
        <div class="promoteInfo_title">成功推广数</div>
        <div class="promoteInfo_num">{{promoteInfo.userCount | watchCount}}</div>
      </van-grid-item>
      <van-grid-item use-slot>
        <div class="promoteInfo_title">总推广收益</div>
        <div class="promoteInfo_num">
          <svg-icon class="goldSvg" iconClass="gold"></svg-icon>
          <span>{{promoteInfo.inviteAmount || '0.00'}}</span>
        </div>
      </van-grid-item>
      <van-grid-item use-slot>
        <div class="promoteInfo_title">今日推广收益</div>
        <div class="promoteInfo_num">
          <svg-icon class="goldSvg" iconClass="gold"></svg-icon>
          <span>{{promoteInfo.todayAmount || '0.00'}}</span>
        </div>
      </van-grid-item>
    </van-grid>

    <div class="app_promote_short_line"></div>

    <!-- 推广logo -->
    <van-grid class="promote_logo" column-num="3" :border="false">
      <van-grid-item use-slot>
        <router-link tag="div" to="incomeBreakdown">
          <svg-icon class="promote_logo_svg" iconClass="icn_agent_jl"></svg-icon>
        </router-link>
        <div class="promote_logo_title">收益明细</div>
      </van-grid-item>
      <van-grid-item use-slot>
        <router-link tag="div" to="promotionRecord">
          <svg-icon class="promote_logo_svg" iconClass="icn_agent_mx"></svg-icon>
        </router-link>
        <div class="promote_logo_title">推广记录</div>
      </van-grid-item>
      <van-grid-item use-slot>
        <router-link tag="div" to="promotionCode">
          <svg-icon class="promote_logo_svg" iconClass="icn_agent_tg"></svg-icon>
        </router-link>
        <div class="promote_logo_title">去推广</div>
      </van-grid-item>
    </van-grid>
    <div class="app_promote_line" style="height: 3px"></div>

    <!-- 推广返利 -->
    <div class="rebateBox">
      <svg-icon class="rebate_title_left" iconClass="icn_agent_ttitle"></svg-icon>
      <div>推广返利</div>
      <svg-icon class="rebate_title_right" iconClass="icn_agent_ttitle"></svg-icon>
    </div>

    <div class="profit_title">
      <div>累计充值</div>
      <div>返利百分比</div>
    </div>
    <div class="app_promote_thinLine"></div>
    <div class="profit_item">
      <div class="left">2千以下</div>
      <div class="right">返利50%</div>
    </div>
    <div class="app_promote_thinLine"></div>
    <div class="profit_item">
      <div class="left">2千～1万</div>
      <div class="right">返利60%</div>
    </div>
    <div class="app_promote_thinLine"></div>
    <div class="profit_item">
      <div class="left">1万～3万</div>
      <div class="right">返利65%</div>
    </div>
    <div class="app_promote_thinLine"></div>
    <div class="profit_item">
      <div class="left">3万以上</div>
      <div class="right">返利70%</div>
    </div>
    <div class="app_promote_line"></div>
    <!-- 推广流程 -->
    <div class="rebateBox">
      <svg-icon class="rebate_title_left" iconClass="icn_agent_ttitle"></svg-icon>
      <div>推广流程</div>
      <svg-icon class="rebate_title_right" iconClass="icn_agent_ttitle"></svg-icon>
    </div>

    <div class="stepBox">
      <div class="stepImg">1</div>
      <div class="stepContentBox">
        <div class="stepTitle">第一步: 点击「<span>去推广</span>」</div>
        <div class="stepContent">将「二维码」分享给其他人或其他渠道，对方扫码下载APP并登录了新账号，则视为推广成功</div>
      </div>
    </div>
    <div class="stepBox">
      <div class="stepImg">2</div>
      <div class="stepContentBox">
        <div class="stepTitle">第二步: 对方「<span>充值</span>」</div>
        <div class="stepContent">推广的用户充值，则充值金额按照「推广级别」的比例返利给您，若有什么疑问请联系「<span>在线客服</span>」</div>
      </div>
    </div>
    <div class="stepBox">
      <div class="stepImg">3</div>
      <div class="stepContentBox">
        <div class="stepTitle">第三步: <span>收益提现</span>或<span>兑换会员</span></div>
        <div class="stepContent">
          <p>收益可以提现或兑换会员，月入万元不是梦！</p>
          <p>立即提现 => 银行卡/支付宝</p>
          <p>兑换会员 => 无限观影</p>
          <div class="example">举例: 推广的用户充值100元，返利60～75，业绩越高比例越高</div>
        </div>
      </div>
    </div>

    <div class="app_promote_line"></div>
    <div class="bottomText">
      <div>一看即会，任何人都可参与，边约炮边赚钱</div>
      <div>终身享受利润分红，任何疑问请联系<span>在线客服</span></div>
    </div>
  </div>
</template>

<script>
  import {
    queryBankCard
  } from '@/api/user.js'
  import {
    queryAppPromoteInfo
  } from '@/api/mine/promote.js'
  export default {
    data() {
      return {
        promoteInfo: {},
        incomeAmount: '',
      }
    },
    created() {
      this.queryAppPromoteInfo();
      this.getWalletInfo();
    },
    methods: {
      // 查询推广信息
      async queryAppPromoteInfo() {
        let ret = await this.$Api(queryAppPromoteInfo, undefined);
        if (ret && ret.code == 200) {
          this.promoteInfo = ret.data;
        }
      },
      // 获取钱包信息
      async getWalletInfo() {
        await this.$store.dispatch('user/setWallet');
        let walletInfo = this.$store.getters.walletInfo;
        this.incomeAmount = walletInfo.income;
      },
      // 去提现
      async toWithdraw() {
        let ret = await this.$Api(queryBankCard);
        if (ret && ret.code == 200) {
          if (ret.data.bankInfo && ret.data.bankInfo.cardNum) {
            this.$router.push('goldWithdraw');
          } else {
            this.$router.push('bindBankCard?t=gold');
          }
        }
      },
    }
  }
</script>

<style lang='scss' scoped>
  .appPromotePage {
    color: $black;
    padding: 0 16px;
    // margin-top: 44px;
    height: calc(100vh - 44px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;

    .app_title {
      font-size: 20px;
      margin-top: 18px;
    }

    .app_amout_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0 14px;

      .app_amount {
        font-size: 30px;
      }

      .app_withdrawBtn {
        width: 112px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        color: $white1;
        background: $vermillion;
        border-radius: 12px;
        font-size: 16px;
      }
    }

    .app_promote_line {
      height: 2px;
      // opacity: 0.5;
      background-image: linear-gradient(90deg, $white1 0%, $whiteFour 50%, $white1 100%);
    }

    .app_promote_short_line {
      margin: 0 46px;
      height: 2px;
      // opacity: 0.5;
      background-image: linear-gradient(90deg, $white1 0%, $whiteFour 50%, $white1 100%);
    }

    .promoteInfo {
      // margin: 20px 0 17px;
      height: 93px;

      .promoteInfo_title {
        margin-bottom: 15px;
        font-size: 14px;

      }

      .promoteInfo_num {
        font-size: 16px;

        .goldSvg {
          margin-right: 2.4px;
        }
      }
    }

    .promote_logo {
      height: 101px;

      // margin: 17px 0 11px;
      .promote_logo_svg {
        width: 50px;
        height: 50px;
      }

      .promote_logo_title {
        font-size: 14px;
        margin-top: 3px;
      }
    }

    .rebateBox {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 25px;
      color: $vermillion;
      font-size: 18px;
      margin: 21px 16px 17px;

      .rebate_title_left {
        height: 25px;
        width: 90px;
      }

      .rebate_title_right {
        height: 25px;
        width: 90px;
        transform: rotate(180deg);
      }
    }

    .profit_title {
      display: flex;
      justify-content: space-around;
      font-size: 16px;
      color: $purpleBrownTwo;
      text-align: center;
      margin-bottom: 9px;
    }

    .app_promote_thinLine {
      width: 100%;
      height: 1px;
      // opacity: 0.5;
      background-image: linear-gradient(90deg, $white1 0%, $whiteSix 50%, $white1 100%);
    }

    .profit_item {
      height: 37px;
      line-height: 33px;
      font-size: 12px;
      color: $vermillion;
      display: flex;
      justify-content: space-around;
      text-align: center;
    }

    .stepBox {
      display: flex;
      padding: 0 40px 23px;

      .stepImg {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        background: $vermillion;
        color: $white1;
        // flex: 1;
      }

      .stepContentBox {
        flex: 2;
        margin-left: 7px;

        .stepTitle {
          height: 30px;
          line-height: 30px;
          font-size: 17px;
          margin-bottom: 5px;
        }

        .stepContent {
          font-size: 11px;
          line-height: 16px;

          .example {
            font-size: 9px;
            line-height: 11px;
            margin-top: 12px;
          }
        }

        span {
          color: $vermillion;
        }
      }
    }

    .bottomText {
      font-size: 11px;
      margin: 11px 0 100px;
      // line-height: 14px;
      text-align: center;

      div {
        margin-bottom: 3px;
      }

      span {
        color: $vermillion;
      }
    }

  }

  .appPromote::-webkit-scrollbar {
    display: none;
  }
</style>